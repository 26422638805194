@import 'style/colors.scss';

.layout {
  display: flex;
  transition: background-color 250ms ease-in;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > h1 + p {
    margin-top: 16px;
  }

  &.mode-error {
    background-color: $red;
  }

  &.mode-success {
    background-color: $green;
  }

  &.mode-default {
    background-color: $background;
  }
}
