@import 'style/colors.scss';

.passcode {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  > input {
    font-size: 24px;
    font-weight: 600;
    width: 48px;
    height: 48px;
    text-align: center;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    vertical-align: top;
    border: 2px solid transparent;
    background: white;
    border-radius: 4px;
    appearance: none;
    color: $background;
    outline: none;
    transition: border 200ms ease-in-out;

    &:focus,
    &:active {
      border: 2px solid $green;
    }

    + input {
      margin-left: 8px;
    }
  }
}
