@import 'style/colors.scss';

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Open Sans', sans-serif;
  background-color: $background;

  a {

    &,
    &:hover,
    &:active,
    &:focus {
      color: $white;
      text-decoration: none;
    }
  }

  h1, p {
    margin: 0;
    padding: 0;
    color: $white;
  }

  h1 {
    font-size: 32px;
    line-height: 40px;
  }

  p {
    font-size: 18px;
    line-height: 24px;
  }

  button {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: $green;
    border: 3px solid $white;
    outline: none;
    cursor: pointer;
    animation: pulse 4s infinite;
  }

  @keyframes pulse {
    0% {
      scale: 1.0
    }
    50% {
      scale: 1.5;
    }
    100% {
      scale: 1.0;
    }
  }
}
